<template>
  <v-app style="background-color: #172b4d">
    <router-view />
  </v-app>
</template>

<script>
export default {
  methods: {},
};
</script>
<style>
@import "./assets/styles.css";
html {
  font-family: "OpenSans" !important;
}
</style>
