import Vue from "vue";
import VueRouter from "vue-router";
import Homepage from "@/views/Homepage.vue";
import login from "../views/Login.vue";
import index from "../views/Index.vue";
import forgotPassword from "@/views/forgotPassword.vue";
import forgotPasswordSuccess from "@/views/forgotPasswordSuccess.vue";

Vue.use(VueRouter);

const routes = [
  // {
  //   path: "/",
  //   name: "index",
  //   component: index,
  // },
  // {
  //   path: "/",
  //   name: "login",
  //   component: login,
  // },
  {
    path: "/Unauthorize",
    name: "Unauthorize",
    component: () => import("@/views/Unauthorize"),
  },
  {
    path: "/ManageSpecies",
    name: "ManageSpecies",
    component: () => import("@/components/Register/ManageSpecies"),
  },
  { path: "/forgotpassword", name: "forgot", component: forgotPassword },
  {
    path: "/forgotPasswordSuccess",
    name: "forgotSuccess",
    component: forgotPasswordSuccess,
  },
  {
    path: "/ExportVat",
    name: "ExportVat",
    component: () => import("@/views/ExportExcel/ExportVat.vue"),
  },
  {
    path: "/invc",
    name: "invc",
    component: () => import("@/views/ExportExcel/ExportInvoiceeee.vue"),
  },
  {
    path: "/",
    component: Homepage,
    children: [
      {
        path: "/",
        name: "Index",
        component: () => import("@/views/Index.vue"),
      },
      {
        path: "/ManagePoints",
        name: "ManagePoints",
        component: () => import("@/views/ManagePoints"),
      },
      {
        path: "/ManageProfile",
        name: "ManageProfile",
        component: () => import("@/views/ManageProfile"),
      },
      {
        path: "/ManageAddress",
        name: "ManageAddress",
        component: () => import("@/views/ManageAddress"),
      },
      {
        path: "/ManageNexGardFamily",
        name: "ManageNexGardFamily",
        component: () =>
          import("@/components/NexGardFamily/ManageNexGardFamily"),
      },
      {
        path: "/ELearningNex",
        name: "ELearningNex",
        component: () => import("@/components/NexGardFamily/ELearningNex"),
      },
      {
        path: "/ManageFRONTLINE",
        name: "ManageFRONTLINE",
        component: () => import("@/components/FRONTLINE/ManageFRONTLINE"),
      },
      {
        path: "/ELearningFRONTLINE",
        name: "ELearningFRONTLINE",
        component: () => import("@/components/FRONTLINE/ELearningFRONTLINE"),
      },
      {
        path: "/ELearningFRONTLINEDigital",
        name: "ELearningFRONTLINEDigital",
        component: () => import("@/components/FRONTLINE/ELearningFRONTLINEDigital"),
      },
      {
        path: "/ManageBroadline",
        name: "ManageBroadline",
        component: () => import("@/components/Broadline/ManageBroadline"),
      },
      {
        path: "/ELearningBroadline",
        name: "ELearningBroadline",
        component: () => import("@/components/Broadline/ELearningBroadline"),
      },
      {
        path: "/ManageHeartgard",
        name: "ManageHeartgard",
        component: () => import("@/components/Heartgard/ManageHeartgard"),
      },
      {
        path: "/ELearningHeartgard",
        name: "ELearningHeartgard",
        component: () => import("@/components/Heartgard/ELearningHeartgard"),
      },
      {
        path: "/ManageRewards",
        name: "ManageRewards",
        component: () => import("@/components/Rewards/ManageRewards"),
      },
      {
        path: "/RewardsPage",
        name: "RewardsPage",
        component: () => import("@/components/Rewards/RewardsPage"),
      },
      {
        path: "/RedemptionDetail",
        name: "RedemptionDetail",
        component: () => import("@/views/RedemptionDetail"),
      },
      // {
      //   path: "/RedeemSuccess",
      //   name: "RedeemSuccess",
      //   component: () => import("@/components/Rewards/RedeemSuccess"),
      // },
      {
        path: "/EventEvaluation",
        name: "EventEvaluation",
        component: () => import("@/components/Rewards/EventEvaluation"),
      },
      {
        path: "/ManageMyRewards",
        name: "ManageMyRewards",
        component: () => import("@/components/Rewards/ManageMyRewards.vue"),
      },
      {
        path: "/couponPage",
        name: "couponPage",
        component: () => import("@/components/Rewards/couponPage.vue"),
      },
      {
        path: "/ManageCE",
        name: "ManageCE",
        component: () => import("@/components/CE/ManageCE"),
      },
      {
        path: "/CreditCE",
        name: "CreditCE",
        component: () => import("@/components/CE/CreditCE.vue"),
      },
      {
        path: "/TestCE",
        name: "TestCE",
        component: () => import("@/components/CE/TestCE"),
      },
      {
        path: "/ManageQuiz",
        name: "ManageQuiz",
        component: () => import("@/components/Quiz/ManageQuiz"),
      },
      {
        path: "/ManageGame",
        name: "ManageGame",
        component: () => import("@/components/game/ManageGame.vue"),
      },
      {
        path: "/gamePig",
        name: "gamePig",
        component: () => import("@/components/game/gamePig.vue"),
      },
      {
        path: "/ManageDAH",
        name: "ManageDAH",
        component: () => import("@/components/DAH/ManageDAH.vue"),
      },
      {
        path: "/ManageNexPOSM",
        name: "ManageNexPOSM",
        component: () => import("@/components/NexGardFamily/ManageNexPOSM"),
      },
      {
        path: "/learnDigitalAsset",
        name: "learnDigitalAsset",
        component: () =>
          import("@/components/NexGardFamily/learnDigitalAsset.vue"),
      },
      {
        path: "/ManageConsulting",
        name: "ManageConsulting",
        component: () => import("@/components/Consulting/ManageConsulting"),
      },
      {
        path: "/EditForm",
        name: "EditForm",
        component: () => import("@/components/Consulting/EditForm.vue"),
      },
      {
        path: "/ManageAppointments",
        name: "ManageAppointments",
        component: () => import("@/components/Appointments/ManageAppointments"),
      },
      {
        path: "/ManageRegister",
        name: "ManageRegister",
        component: () => import("@/components/Register/ManageRegister"),
      },
      {
        path: "/Policy",
        name: "Policy",
        component: () => import("@/components/Register/Policy.vue"),
      },
      {
        path: "/Verify",
        name: "Verify",
        component: () => import("@/components/Register/Verify.vue"),
      },
      {
        path: "/OTP",
        name: "OTP",
        component: () => import("@/components/Register/OTP.vue"),
      },
      {
        path: "/createAccount",
        name: "createAccount",
        component: () => import("@/components/Register/createAccount.vue"),
      },
      {
        path: "/ManageAccount",
        name: "ManageAccount",
        component: () => import("@/components/Register/ManageAccount.vue"),
      },
      // {
      //   path: "/ManageSpecies",
      //   name: "ManageSpecies",
      //   component: () => import("@/components/Register/ManageSpecies"),
      // },
      {
        path: "/KnowledgeHub",
        name: "KnowledgeHub",
        component: () => import("@/views/KnowledgeHub"),
      },
      {
        path: "/DigitalAssetsHub",
        name: "DigitalAssetsHub",
        component: () => import("@/views/DigitalAssetsHub"),
      },
      {
        path: "/DigitalAssetsHubDetail",
        name: "DigitalAssetsHubDetail",
        component: () => import("@/views/DigitalAssetsHubDetail"),
      },
      {
        path: "/KnowledgeHubDetail",
        name: "KnowledgeHubDetail",
        component: () => import("@/views/KnowledgeHubDetail"),
      },
      {
        path: "/Notification",
        name: "Notification",
        component: () => import("@/views/Notification"),
      },
      // {
      //   path: "/Createjob",
      //   name: "Createjob",
      //   component: () => import("@/components/Species/Createjob.vue"),
      // },
      // {
      //   path: "/Viewjob",
      //   name: "Viewjob",
      //   component: () => import("@/components/job/Viewjob.vue"),
      // },
      // {
      //   path: "/Editjob",
      //   name: "Editjob",
      //   component: () => import("@/components/job/Editjob.vue"),
      // },
      // {
      //   path: "/Managelicenses",
      //   name: "Managelicenses",
      //   component: () => import("@/views/Managelicenses"),
      // },
      // {
      //   path: "/Createlicenses",
      //   name: "Createlicenses",
      //   component: () => import("@/components/licenses/Createlicenses.vue"),
      // },
      // {
      //   path: "/Viewlicenses",
      //   name: "Viewlicenses",
      //   component: () => import("@/components/licenses/Viewlicenses.vue"),
      // },
      // {
      //   path: "/Editlicenses",
      //   name: "Editlicenses",
      //   component: () => import("@/components/licenses/Editlicenses.vue"),
      // },
      // {
      //   path: "/Manageskills",
      //   name: "Manageskills",
      //   component: () => import("@/views/Manageskills"),
      // },
      // {
      //   path: "/Createskills",
      //   name: "Createskills",
      //   component: () => import("@/components/skills/Createskills.vue"),
      // },
      // {
      //   path: "/Viewskills",
      //   name: "Viewskills",
      //   component: () => import("@/components/skills/Viewskills.vue"),
      // },
      // {
      //   path: "/Editskills",
      //   name: "Editskills",
      //   component: () => import("@/components/skills/Editskills.vue"),
      // },
      // {
      //   path: "/Report1",
      //   name: "Report1",
      //   component: () => import("@/components/Report/Report1.vue"),
      // },
      // {
      //   path: "/Setting",
      //   name: "Setting",
      //   component: () => import("@/views/Setting.vue"),
      // },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
